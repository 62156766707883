<template>
  <div class="d-flex mobile-navbar-container fixed-top align-items-stretch d-md-none">
    <div class="flex-fill mobile-navbar-item">
      <burger></burger>
    </div>
    <div class="flex-fill mobile-navbar-item">
      <router-link to="/" class="mobile-navbar-logo-anchor">
        <img
          width="42"
          height="37"
          itemprop="image"
          src="@/assets/images/logo-mobile.png"
          alt="Logo Mobile"
          loading="lazy"
        />
      </router-link>
    </div>
    <div class="flex-fill mobile-navbar-item">
      <button class="footer__link" @click="searchBtnAction">
        <img
          width="23"
          height="23"
          src="@/assets/images/search_mobile.svg"
          alt="Search"
          fetchpriority="high"
        />
      </button>
    </div>
    <div class="flex-fill mobile-navbar-item">
      <router-link
        class="nav-link reduceri-link"
        to="/promotie-seminee-promotii"
        title="Promotii pefoc.ro"
        ><span>OFERTE</span></router-link
      >
    </div>
    <div class="flex-fill mobile-navbar-item">
      <phone-navbar class="mobile-navbar-phone"></phone-navbar>
    </div>
    <div class="flex-fill mobile-navbar-item">
      <mini-cart-card class="mobile-navbar-cart"></mini-cart-card>
    </div>
    <sticky-search
      class="mobile-navbar-input-search"
      ref="inputsearchnav"
      :is-visible="false"
    ></sticky-search>

    <Sidebar>
      <MenuMobileItems :categories="categories" />
    </Sidebar>
  </div>
</template>

<script>
import PhoneNavbar from "@/components/header/PhoneNavbar.vue";
import MiniCartCard from "@/components/header/minicart/MiniCartCard.vue";
import StickySearch from "@/components/header/sticky/StickySearch.vue";
import Burger from "@/components/menu/Burger.vue";
import Sidebar from "@/components/menu/Sidebar.vue";
import MenuMobileItems from "@/components/menu/MenuMobileItems.vue";

export default {
  components: {
    PhoneNavbar,
    MiniCartCard,
    StickySearch,
    Burger,
    Sidebar,
    MenuMobileItems,
  },
  computed: {
    categories() {
      // console.log(this.$store.getters["shop/categories"]);
      return this.$store.getters["shop/categories"];
    },
  },

  methods: {
    searchBtnAction() {
      this.$refs.inputsearchnav.enterNavSearch();
    },
  },
  created() {
    // if (!this.categories.length) {
    //   this.$store.dispatch('shop/getCategories');
    // }
  },
};
</script>

<style scoped>
.mobile-navbar-item {
  width: 20%;
}

.mobile-navbar-item > * {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mobile-navbar-item button {
  width: 100%;
}

.mobile-navbar-item i {
  color: #fff;
  font-size: 26px;
}

.mobile-navbar-logo-anchor {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #97040c;
  text-align: center;
  height: 100%;
}

.mobile-navbar-logo-anchor img {
  max-width: 72%;
}

.mobile-navbar-container {
  /* border-bottom: 1px solid #cf0000; */
  background-color: #000;
}

.mobile-navbar-item .footer__link {
  opacity: 1;
}

.mobile-navbar-item .reduceri-link {
  background: transparent;
}

.mobile-navbar-item .reduceri-link:after {
  background-size: 20px;
  width: 20px;
  height: 20px;
}

@media (max-width: 767px) {
  .mobile-navbar-container {
    height: 100px;
    z-index: 999;
  }
}

@media (max-width: 576px) {
  .mobile-navbar-container {
    height: 60px;
  }
}
</style>
