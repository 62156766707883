<template>
  <div>
    <a
      href="tel:0377101500"
      class="link"
      aria-label="Call "
      @mouseenter="showNumber"
      @mouseleave="hideNumber"
    >
      <span class="phone-digits">0377 101 500</span
      >
      <img width="23" height="23" class="fa-phone" src="@/assets/images/phone_mobile.svg" alt="Search" fetchpriority="high"/>
      <div v-if="phoneIsShow" class="show-phone">
        <div class="smg load-fc"></div>
        <span class="load-fc">Apelează-ne: 0377 101 500</span>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phoneIsShow: false,
    };
  },

  methods: {
    showNumber() {
      this.phoneIsShow = true;
    },
    hideNumber() {
      const _self = this;
      setTimeout(function () {
        _self.phoneIsShow = false;
      }, 3000);
    },
  },
};
</script>

<style scoped>
.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.show-phone {
  width: 280px;
  background-color: #fff;
  z-index: 12;
  position: absolute;
  right: 0;
  top: 60px;
  padding: 10px;
  border-radius: 50px;
  text-align: center;
}

.smg {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid white;
  position: absolute;
  right: 20px;
  top: -5px;
}

.show-phone .load-fc {
  color: #000;
  font-size: 14px;
  line-height: 20px;
  font-family: "Montserrat Bold";
}

.phone-digits {
  display: none;
}

.stickyphoneNumber .phone-digits {
  display: block;
  font-family: Montserrat SemiBold;
  padding: 0;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0.4px;
  font-weight: 600;
  color: #fff;
}

.stickyphoneNumber .fa-phone {
  display: none;
}

.stickyphoneNumber .show-phone,
.mobile-navbar-phone .show-phone {
  display: none;
}

.mobile-navbar-phone i {
  color: #fff;
  font-size: 26px;
}

@media (max-width: 1200px) {
  .stickyphoneNumber .fa-phone {
    display: block;
    color: #000;
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
  }

  .stickyphoneNumber {
    background-color: #ffffff;
    height: 100%;
    width: 100%;
  }

  .stickyphoneNumber .phone-digits {
    display: none;
  }

  .stickyphoneNumber > i {
    display: block;
    color: #000000;
  }
}
</style>
