<template>
  <!-- if cart_is_available -->
  <div class="h-100 w-100">
    <router-link
      @mouseenter="showMiniCart(true)"
      @mouseleave="showMiniCart(false)"
      to="/cos-cumparaturi"
      class="header__cart link cart_link_header_wrapper"
    >
      <div class="position-relative">
        <img width="24" height="22" src="@/assets/images/cart.svg" alt="Cos Pefoc.ro" fetchpriority="high"/>
        <span
          v-if="cartIsLoaded && cartData.quantity"
          class="header__cart-badge header__cart-badge-pf"
          >{{ cartData.quantity > 0 ? cartData.total_quantity : 0 }}</span
        >
        <span
          v-if="cartIsLoaded && cartData.length === 0"
          class="header__cart-badge header__cart-badge-pf"
          >0</span
        >
      </div>
      <span class="cart-caption">Cosul meu</span>
    </router-link>

    <div
      v-if="cartData.quantity > 0"
      @mouseenter="showMiniCart(true)"
      @mouseleave="showMiniCart(false)"
    >
      <mini-cart v-if="cartIsShow" :key="1"></mini-cart>
    </div>
    <div v-else @mouseenter="showMiniCart(true)" @mouseleave="showMiniCart(false)">
      <MiniCartNoProducts v-if="cartIsShow" />
    </div>
  </div>

  <!-- endif -->
</template>

<script>
import { mapGetters } from "vuex";
import { defineAsyncComponent } from "vue";

export default {
  components: {
    MiniCart: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "minicart" */ "@/components/header/minicart/MiniCart.vue"
      )
    ),
    MiniCartNoProducts: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "minicartnoproducts" */ "@/components/header/minicart/MiniCartNoProducts.vue"
      )
    ),
  },
  data() {
    return {
      cartIsShow: false,
      cartIsChange: false,
      count: 0,
    };
  },
  methods: {
    showMiniCart(value) {
      this.count = this.count + 1;
      if (value == false) {
        this.cartIsChange = false;
        setTimeout(() => {
          if (this.cartIsChange != true) {
            this.cartIsShow = false;
            this.cartIsChange = false;
          }
        }, 1000);
      } else {
        this.cartIsChange = true;
        this.cartIsShow = true;
      }
    },
  },
  computed: {
    ...mapGetters("shop", ["cartData"]),
    cartIsLoaded() {
      return this.$store.getters["shop/cartIsLoaded"];
    },
    // productName(item) {
    //   console.log(item);
    //   let name = "";
    //   if (Object.prototype.hasOwnProperty.call(item, "property")) {
    //     name = item.property["custom-name"];
    //   } else {
    //     name = item.product.name;
    //   }
    //   return name;
    // },
  },
  created() {
    // console.log(this.cartData, "state");
  },
};
</script>

<style scoped>
.cart-caption {
  display: none;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.cart-caption {
  color: #fff;
  font-family: Montserrat Regular;
  font-size: 12px;
  text-transform: none;
  padding-left: 10px;
}

.header__cart .fa {
  font-size: 20px;
  color: #fff;
}

.mobile-navbar-cart i.fa {
  font-size: 26px;
  color: #fff;
}

.mobile-navbar-cart #mini-cos {
  display: none;
}

@media (min-width: 1200px) {
  .sticky-cart .cart-caption {
    display: block;
  }
}

@media (max-width: 1200px) {
  .sticky-cart {
    background-color: #ffffff;
  }

  .sticky-cart div img {
    display: block;
    color: #000000;
    filter: invert(100%);
  }

  .cart a {
    border-right: 1px solid rgba(40, 17, 7, 0.8);
  }

  .sticky-cart .header__cart-badge-pf {
    background: #c0050f;
    border: #000;
    color: #ffffff;
  }
}
</style>
