<template>
    <footer
        class="footer"
        :class="{ scrollProductNavBar: addtocartnavbar }"
        itemscope
        itemtype="http://schema.org/WPFooter"
    >
        <div
            class="container"
            itemprop="about"
            itemscope
            itemtype="http://schema.org/Organization"
        >
            <div class="row">
                <div
                    class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 footer-contact footer-item col_p_left"
                >
                    <!-- <call-block /> -->
                    <!-- <email-block /> -->
                    <!-- <m-pages-block /> -->
                    <social-block />
                    <div class="about-us-block">
                        <router-link to="/despre-noi"
                            ><div class="d-none d-md-block">Descopera povestea PEFOC</div>
                            <div class="d-block d-md-none">
                                Povestea PEFOC
                            </div></router-link
                        >
                    </div>
                    <rights-block class="d-none d-md-block" />
                    <!-- <m-business-details /> -->
                    <!-- <m-info-block /> -->
                </div>

                <div
                    class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 footer-terms footer-item d-md-block"
                >
                    <informations-block />
                </div>

                <div
                    class="col-12 col-sm-12 col-md-4 col-lg-5 col-xl-6 footer-terms d-md-block footer-item col_p_right border-md-0"
                >
                    <cards-block />
                    <!-- <footer-blog /> aici trebuie facut query pe blog-uri daca se reintroduce -->
                </div>

                <div class="col-12 d-xs-block d-md-none mt-4 border-0">
                    <rights-block />
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
// import CallBlock from "@/components/footer/CallBlock.vue";
// import EmailBlock from "@/components/footer/EmailBlock.vue";
// import MPagesBlock from "@/components/footer/MPagesBlock.vue";
import RightsBlock from "@/components/footer/RightsBlock.vue";
import SocialBlock from "@/components/footer/SocialBlock.vue";
// import MBusinessDetails from "@/components/footer/MBusinessDetails.vue";
// import MInfoBlock from "@/components/footer/MInfoBlock.vue";
import InformationsBlock from "@/components/footer/InformationsBlock.vue";
import CardsBlock from "@/components/footer/CardsBlock.vue";
// import FooterBlog from "@/components/footer/FooterBlog.vue";

export default {
    name: "TheFooter",
    components: {
        // "call-block": CallBlock,
        // "email-block": EmailBlock,
        // "m-pages-block": MPagesBlock,
        "rights-block": RightsBlock,
        "social-block": SocialBlock,
        // "m-business-details": MBusinessDetails,
        // "m-info-block": MInfoBlock,
        "informations-block": InformationsBlock,
        "cards-block": CardsBlock,
        // "footer-blog": FooterBlog,
    },
    computed: {
        addtocartnavbar() {
            return this.$store.getters["shop/addToCartNavbar"];
        },
    },
};
</script>

<style>
@import "../../assets/css/footer.css";

.scrollProductNavBar {
    margin-bottom: 70px;
}
</style>
